import {React, StatePool, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import IconArrow from '../images/icon_arrow.png';
import IconZoom from '../images/icon_zoom.png';
import IconPoint from '../images/icon_point.png';
import IconStarBlack from '../images/icon_star_black.png';
import IconDownloads from '../images/new_icon_downloads.svg';
import VerifyDeveloper from '../images/verify-dev.jpg';
import NoLogo from '../no-logo.png';
import {useData} from "../../../Context/ContextApp";
import TranslateText from "../Translate/text-translate.json";

let IconPwa = StatePool.createState('');
let NamePwa = StatePool.createState('');
let DeveloperName = StatePool.createState('');
let Categories = StatePool.createState('');
let AgeUser = StatePool.createState('');
let AppSize = StatePool.createState('');
let AmountInstall = StatePool.createState('');
let ButtonText = StatePool.createState('');
let InstallMount = StatePool.createState('');

const SectionTop = () => {
    let {data} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    let [landIcon, setLandIcon] = StatePool.useState(IconPwa);
    let [landName, setLandName] = StatePool.useState(NamePwa);
    let [developerName, setDeveloperName] = StatePool.useState(DeveloperName);
    let [categories, setCategories] = StatePool.useState(Categories);
    let [ageUser, setUserAge] = StatePool.useState(AgeUser);
    let [appSize, setAppSize] = StatePool.useState(AppSize);
    let [amountInstall, setAmountInstall] = StatePool.useState(AmountInstall);
    let [buttonText, setButtonText] = StatePool.useState(ButtonText);
    let [installAmount, setInstallAmount] = StatePool.useState(InstallMount);

    let handleLandIcon = (valueIcon) => {
        setLandIcon(valueIcon)
    };

    let handleLandName = (valueName) => setLandName(valueName);
    let handleDeveloperName = (developer) => setDeveloperName(developer);
    let handleCategories = (categories) => setCategories(categories);
    let handleAgeUser = (age) => setUserAge(age);
    let handleAppSize = (size) => setAppSize(size);
    let handleAmountInstall = (amount) => setAmountInstall(amount);
    let handleButtonText = (text) => setButtonText(text);
    let handleInstallAmount = (mount) => setInstallAmount(mount);

    useEffect(()=> {
        handleLandIcon(data.iconUrl);
        handleLandName(data.landName);
        handleDeveloperName(data.author)
        handleCategories(data.categories);
        handleAgeUser(data.age);
        handleAppSize(data.appSize);
        handleAmountInstall(data.amountComments);
        handleButtonText(data.buttonText);
        handleInstallAmount(data.amountInstall);
    }, [data]);

    return (
        <section className={`${PWAPreviewStyle.top} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}`}>

            <div className={PWAPreviewStyle.top__line}>
                <div className={PWAPreviewStyle.top__line_left}>
                    <img src={IconArrow} alt=""/>
                </div>
                <div className={PWAPreviewStyle.top__line_right}>
                    <div className={PWAPreviewStyle.zoom}>
                        <img src={IconZoom} alt=""/>
                    </div>
                    <div className={PWAPreviewStyle.menu}>
                        <img src={IconPoint} alt=""/>
                    </div>
                </div>
            </div>

            <div className={PWAPreviewStyle.title}>
                <div className={PWAPreviewStyle.title__avatar}>
                    <img
                        src={landIcon.length > 0 ? landIcon : NoLogo}
                        alt=""
                        style={{borderRadius: 12}}/>
                </div>
                <div className={PWAPreviewStyle.title__name}>
                    <p className={PWAPreviewStyle.main_name} style={{fontSize: 19}}>
                        {landName}
                    </p>
                    <p className={PWAPreviewStyle.name_down}>
                        {developerName}
                        <img src={VerifyDeveloper} style={{display: data.verifiedDeveloper ? 'inline-block' : 'none'}} className={PWAPreviewStyle.imageVerifyDeveloper} alt="" />
                    </p>
                    <p className={PWAPreviewStyle.name_down} style={{fontSize: 11, color: 'gray'}}>
                        {categories.length > 0 ? categories : 'Categories App'}
                    </p>
                    <div className={PWAPreviewStyle.counter}>
                        <span id={PWAPreviewStyle.count} style={{fontSize: "20px", fontFamily: "arial"}}>
    100%
</span>
                        <p>out of {appSize}MB</p>
                    </div>
                </div>
            </div>

            <div className={PWAPreviewStyle.info_line}>
                <div className={PWAPreviewStyle.infoElement}>
                    <div className={PWAPreviewStyle.info_line__left}>
                        <p>{data.ratingStar}<img src={IconStarBlack} alt=""/></p>
                        <p>
                            {amountInstall.length > 0 ? amountInstall : '103'}
                            {data.langCode.length > 0 ? ` ${ListTextSecurity['comments_text_top']}` : ' Comments'}
                        </p>
                    </div>
                </div>
                <div className={PWAPreviewStyle.infoElement}>
                    <div className={PWAPreviewStyle.info_line__center}>
                        <div className={PWAPreviewStyle.center}>
                            <div className={PWAPreviewStyle.center__icon}>
                                <img src={IconDownloads} alt=""/>
                            </div>
                            <p>
                            <span style={{color: '#000'}}>{appSize.length > 0 ? appSize : '6.5'}</span> MB</p>
                        </div>
                    </div>
                </div>
                <div className={PWAPreviewStyle.infoElement}>
                    <div className={PWAPreviewStyle.info_line__right}>
                        {/*<p className={PWAPreviewStyle.age_user_app}>+{!ageUser ? '18' : ageUser}</p>*/}
                        <p className={PWAPreviewStyle.age_user_app}>
                            <img src={`https://easy-image.b-cdn.net/icon/icon_${!ageUser ? '18' : ageUser}.webp`} alt=""/>
                        </p>
                        <p>{data.langCode.length > 0 ? ListTextSecurity['age_text'] : '+18'}</p>
                    </div>
                </div>
                <div className={PWAPreviewStyle.infoElement}>
                    <div className={PWAPreviewStyle.info_line__right}>
                        <p className={PWAPreviewStyle.amountInstall}>{installAmount.length > 0 ? installAmount : '8273'}</p>
                        <p>{data.langCode.length > 0 ? ListTextSecurity['install_text'] : 'Installation'}</p>
                    </div>
                </div>
            </div>

            <div className={PWAPreviewStyle.btn_container}>
                <div className={`${PWAPreviewStyle.btn} ${PWAPreviewStyle.js_first_button}`}>
                    <p className={`${PWAPreviewStyle.btn__link} ${PWAPreviewStyle.firstButton}`} id="firstButton">
                        {data.langCode.length > 0 ? ListTextSecurity['install_btn'] : 'Install'}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default SectionTop;