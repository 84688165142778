import {React, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import { useData } from "../../../Context/ContextApp";
import TranslateText from '../Translate/text-translate.json';

const BlockCommentQuestion = () => {
    let {data, updateData} = useData();
    const ListTextSecurity = TranslateText[data.langCode];

    return (
        <div className={PWAPreviewStyle.comment_question} style={{marginTop: data.rtlDirection ? '10px' : ''}}>
            <p className={PWAPreviewStyle.comment_question__content}>
                {data.langCode.length > 0 ? ListTextSecurity['footer_title_question'] : 'Was this information useful to you?'}
            </p>
            <div className={PWAPreviewStyle.btn_container}>
                <div className={`${PWAPreviewStyle.comment_btn} ${PWAPreviewStyle.btn__yes}`}>
                    {data.langCode.length > 0 ? ListTextSecurity['footer_answer_yes'] : 'Yes'}
                </div>
                <div className={`${PWAPreviewStyle.comment_btn} ${PWAPreviewStyle.btn__no}`}>
                    {data.langCode.length > 0 ? ListTextSecurity['footer_answer_no'] : 'No'}
                </div>
            </div>
        </div>
    );
};

export default BlockCommentQuestion;