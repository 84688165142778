import { useEffect, useRef } from "react";
import { React, useState, useCallback } from "../../../imports/imports-packege";
import ColorSelectionStyle from "./ColorSelectionStyle.module.scss";
import { SketchPicker } from 'react-color';
import { useData } from "../../Context/ContextApp";
import TranslateBuilderText from '../../TranslatePagesElement/translate-constructor.json';

const ColorSelection = ({ statusCheck, getSaveStatus }) => {
    const { data, updateData } = useData();
    const [translateCode, setTranslateCode] = useState('en');
    const queryParams = new URLSearchParams(window.location.search);
    const translateQueryParameter = queryParams.get('ln');
    
    const handleTranslate = (code_translate) => {
        setTranslateCode(code_translate)
    }
    useEffect(() => {
        handleTranslate(translateQueryParameter)
    }, [translateQueryParameter])

    const [isChecked, setIsChecked] = useState();
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [customColor, setCustomColor] = useState('#F5A623');
    const pickerRef = useRef(null);
    const [activeButton, setActiveButton] = useState("blue");

    document.documentElement.style.setProperty("--main-color", data.mainElementColor);

    const updateColors = (baseColor) => {
        document.documentElement.style.setProperty("--main-color", baseColor);
        updateData({ mainElementColor: baseColor});
    };
    
    const handleChange = useCallback((event) => {
        setIsChecked(event.target.checked);
        statusCheck(event.target.checked);
    }, [statusCheck]);

    const handleColorChange = (color) => {
        setCustomColor(color.hex);
        updateColors(color.hex);
    };

    const togglePicker = () => {
        if (!isPickerOpen) {
            updateColors(customColor)
            setActiveButton("custom");
        }

        setIsPickerOpen((prev) => !prev);
    };
    
    const handleButtonClick = (buttonType, color) => {
        updateData({elementColor: color });
        setActiveButton(buttonType);
        setIsPickerOpen(false);
        updateColors(color);
    };
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setIsPickerOpen(false);
            }
        };

        if (isPickerOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPickerOpen]);

    return (
        <div className={ColorSelectionStyle.ColorSelectionContainer}>
            <div className={ColorSelectionStyle.ColorSelectionBlock}>
                <div 
                    className={`${ColorSelectionStyle.ColorSelectionBlock__button} ${ColorSelectionStyle.ColorSelectionBlock__button_blue}`}
                    style={{
                        border: activeButton === "blue" ? "2px solid red" : "2px solid #fff",
                    }}
                    onClick={() => handleButtonClick("blue", "#0957cf")}
                ></div>
                <div 
                    className={`${ColorSelectionStyle.ColorSelectionBlock__button} ${ColorSelectionStyle.ColorSelectionBlock__button_green}`}
                    style={{
                        border: activeButton === "green" ? "2px solid red" : "2px solid #fff",
                    }}
                    onClick={() => handleButtonClick("green", "#03875f")}

                ></div>
            </div>

            <div className={ColorSelectionStyle.ColorSelectionBlock}>
                <div
                    className={ColorSelectionStyle.ColorSelectionBlock__subtitle}
                >
                    {TranslateBuilderText[translateCode]['app-page']['custom-colour']}
                </div>
                <div
                    className={`${ColorSelectionStyle.ColorSelectionBlock__button} ${ColorSelectionStyle.ColorSelectionBlock__button_green}`}
                    style={{
                        backgroundColor: customColor,
                        border: activeButton === "custom" ? "2px solid red" : "2px solid #fff",
                    }}
                    onClick={togglePicker}
                ></div>
                {isPickerOpen && (
                    <div 
                        className={ColorSelectionStyle.ColorSelectionBlock__picker}
                        ref={pickerRef}
                    >
                        <SketchPicker color={customColor} onChange={handleColorChange} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ColorSelection;