import {React, useEffect, useState} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import RecommendedAppImages from '../images/recommended-app.png';
import { useData } from "../../../Context/ContextApp";
import {AviatorIcon, BalloonIcon, BigBassIcon, CrownIcon, HellHotIcon, JokerIcon, PlinkIcon, SweetIcon, WolfIcon, ZeusIcon, BookOfRa, Burning, Bandidos, ElvisFrog} from '../../../RecommendedAppIcon/RecomendedAppIcon';
import ElementPageTranslate from '../Translate/text-translate.json';

const SectionRecommendedApp = () => {
    const {data} = useData();
    const [developerName, setDeveloperName] = useState('');
    const handleDeveloperName = (developer_name) => {
        setDeveloperName(developer_name);
    }
    useEffect(() => {
        handleDeveloperName(data.author);
    }, [data.author]);

    const ObjectDataIconApp= {
        'aviator': {
            'icon': AviatorIcon,
            'name': 'Aviator'
        },
        'balloon': {
            'icon': BalloonIcon,
            'name': 'Balloon'
        },
        'big_bass': {
            'icon': BigBassIcon,
            'name': 'Big Bass'
        },
        'crown': {
            'icon': CrownIcon,
            'name': 'Crown'
        },
        'hell_hot': {
            'icon': HellHotIcon,
            'name': 'Hell Hot'
        },
        'joker': {
            'icon': JokerIcon,
            'name': 'Joker'
        },
        'plinko': {
            'icon': PlinkIcon,
            'name': 'Plinko'
        },
        'sweet': {
            'icon': SweetIcon,
            'name': 'Sweet Bonanza'
        },
        'wolf': {
            'icon': WolfIcon,
            'name': 'Wolf'
        },
        'zeus': {
            'icon': ZeusIcon,
            'name': 'Zeus'
        },
        'book_of_ra': {
            'icon': BookOfRa,
            'name': 'Book of Ra'
        },
        'burning': {
            'icon': Burning,
            'name': 'Burning Helli'
        },
        'bandidos': {
            'icon': Bandidos,
            'name': 'Bandidos'
        },
        'elvis_frog': {
            'icon': ElvisFrog,
            'name': 'Elvis Frog'
        }
    }

    const [selectedApp, setSelectedApp] = useState([]);
    const [ImageSelectList, setImageSelectList] = useState([]);

    useEffect(() => {
        setSelectedApp(data.recommendedApp);
        const newList = data.recommendedApp.map(item => ObjectDataIconApp[item]);
        setImageSelectList(newList);
    }, [data.recommendedApp]);

    const randomNumber = (min, max) =>  {
        let randomNumber = Math.random() * (max - min) + min;
        return randomNumber.toFixed(2);
    }

    return (
        <section className={`${PWAPreviewStyle.recommended_apps} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}`}>
            <h1 className={PWAPreviewStyle.recommend__title}>
                {developerName === '' ? 'Developer name' : developerName}:
                {ElementPageTranslate[data.langCode === '' ? 'en' : data.langCode]['other_apps']}
            </h1>

            <div className={PWAPreviewStyle.recommended_scroll__container}>
                {
                    ImageSelectList.map((elem, key) => (
                            <div key={key} className={PWAPreviewStyle.recommended_app}>
                                <div
                                     className={`${PWAPreviewStyle.recommended_app__icon} ${PWAPreviewStyle.js_event}`}>
                                    <img src={elem['icon']} alt="Screen"/>
                                </div>
                                <p className={PWAPreviewStyle.recommended_app__name}>{elem['name']}</p>
                                <p className={PWAPreviewStyle.recommended_app__rating}>{randomNumber(4.3, 5)}</p>
                            </div>
                        )
                    )
                }
            </div>
        </section>
    )
}

export default SectionRecommendedApp