import { React, useEffect, useState } from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import { useData } from "../../../Context/ContextApp";
import BlockCommentQuestion from './BlockCommentQuestion';
import ElementPageTranslate from '../Translate/text-translate.json';
import RatingStars from "../../../UI/RatingStar/RatingStar";

const SectionReview = () => {
    const { data } = useData();
    const [langReview, setLangReview] = useState('');
    const [reviewItems, setReviewItems] = useState([]);
    const [daysCounter, setDaysCounter] = useState(0);
    const [showAll, setShowAll] = useState(false);

    const handleLangReview = (langCode) => {
        setLangReview(langCode);
    }

    const randomNumber = (min, max) => {
        let randomNumber = Math.random() * (max - min) + min;
        return randomNumber.toFixed(0);
    }

    function decreaseDate(dayMinus) {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - dayMinus);

        let day = currentDate.getDate();
        let month = currentDate.getMonth() + 1;
        let year = currentDate.getFullYear() % 100;

        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;

        return `${day}.${month}.${year}`;
    }

    function ShowAllReview() {
        setShowAll(true);
    }

    const AddReview = () => {
        let DataReview = data.templateReview;
        DataReview.sort((a, b) => {
            if (!a.date || !b.date) return 0;
            const [dayA, monthA, yearA] = a.date.split('.');
            const [dayB, monthB, yearB] = b.date.split('.');

            const dateA = new Date(`20${yearA}-${monthA}-${dayA}`);
            const dateB = new Date(`20${yearB}-${monthB}-${dayB}`);

            return dateB - dateA ;
        });
        let newReviews = DataReview.map((element, index) => (
            <div key={index} className={`${PWAPreviewStyle.PreviewReview} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}`} style={{ display: showAll || index <= 4 ? 'block' : 'none' }}>
                <div className={PWAPreviewStyle.PreviewReview__InfoUser}>
                    <p className={PWAPreviewStyle.PreviewReview__Container_Logo}
                       style={{ backgroundColor: element['backgroundColor'] }}>
                        <img
                            src={element['icon']}
                            alt=""
                            className={PWAPreviewStyle.PreviewReview__Logo}
                            style={{
                                display: (element['iconType'] === 0) ? 'none' :
                                    (element['iconType'] === 1) ? 'inline-block' :
                                        (element['iconType'] === 'icon') ? 'inline-block' : 'none'
                            }} />
                        <span
                            className={PWAPreviewStyle.PreviewReview__Symbol}
                            style={{
                                display: (element['iconType'] === 0) ? 'flex' :
                                    (element['iconType'] === 1) ? 'none' :
                                        (element['iconType'] === 'icon') ? 'none' : 'flex'
                            }}>
                            {element['name'].charAt(0).toUpperCase()}
                        </span>
                    </p>
                    <p
                        className={PWAPreviewStyle.PreviewReview__NameDeveloper}
                    >
                        {element['name']}
                    </p>
                </div>
                <div className={PWAPreviewStyle.RatingDate}><RatingStars rating={element['rating']} /> <span>{element['date']}</span></div>
                <div className={PWAPreviewStyle.PreviewReview__ReviewTextContainer}>
                    <p
                        className={PWAPreviewStyle.PreviewReview__TextReview}
                    >
                        {element['comment']}
                    </p>
                    <div className={PWAPreviewStyle.PreviewReview__TextResponseDeveloper} style={{display: element['developer'].length === 0 ? 'none' : 'block'}}>
                        <p className={PWAPreviewStyle.DeveloperResponse_title}>
                            <span>Developer</span>
                            <span>{element['date']}</span>
                        </p>
                        {element['developer']}
                    </div>
                </div>
                <p className={PWAPreviewStyle.comment_review} style={{display: data.rtlDirection ? 'none' : 'block'}}>
                    <span>{randomNumber(83, 184)}</span> {ElementPageTranslate[data.langCode ? data.langCode : 'en']['review_text_count_like']}
                </p>
                <BlockCommentQuestion />
            </div>
        ));
        setReviewItems(newReviews);
    }

    useEffect(() => {
        AddReview();
    }, [data, langReview, showAll]);

    return (
        <section className={`${PWAPreviewStyle.comments}`}>
            {reviewItems}
            {!showAll && <div className={`${PWAPreviewStyle.BtnShowAllReview} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}`} onClick={ShowAllReview}>{ElementPageTranslate[data.langCode ? data.langCode : 'en']['show_review_button']}</div>}
        </section>
    );
};

export default SectionReview;
