import {React, useEffect, useState } from "../../../imports/imports-packege";
import Switch from "react-switch";
import { useData } from "../../Context/ContextApp";

const Switcher = ({ contextKey }) => {
    let {data, updateData} = useData();
    const [statusSwitcher, setStatusSwitcher] = useState(false || data[contextKey]);

    const handleSwitcher = function(checked) {
        setStatusSwitcher(checked)
    };

    useEffect(() => {
        updateData({ ...data, [contextKey]:  statusSwitcher});
    }, [statusSwitcher, contextKey])

    return (
        <div>
            <Switch onChange={handleSwitcher} checked={statusSwitcher} />
        </div>
    )
}

export default Switcher;