import {React, useEffect, useState} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import { useData } from "../../../Context/ContextApp";
import CategoriesTranslate from '../../../TranslatePagesElement/translate-categories-app.json';

const SectionCategories = () => {
    const {data} = useData();
    const [selectedCategories, setSelectCategories] = useState([]);
    const handleSelectedCategories = (arrayCategories) => {
        let valueCategories = [];
        for (let item in arrayCategories) {
            valueCategories.push(arrayCategories[item]['value']);
        }
        setSelectCategories(valueCategories)
    }

    const [translateCategories, setTranslateCategories] = useState([]);
    const handleTranslateCategories = (lang, categories = []) => {
        let objectTranslateCategories = CategoriesTranslate[lang];
        let updatedTranslateCategories = categories.map((element) => objectTranslateCategories[element]);
        setTranslateCategories(updatedTranslateCategories);
    };

    useEffect(() => {
        if (data.multiCategories.length === 0) {
            setTranslateCategories([]);
        } else if (data.multiCategories.length !== 0 && data.langCode !== '') {
            const newSelectedCategories = [...data.multiCategories];
            handleSelectedCategories(newSelectedCategories);
            handleTranslateCategories(data.langCode, newSelectedCategories);
        }
    }, [data.multiCategories, data.langCode]);

    return (
        <section className={`${PWAPreviewStyle.categories} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}` }>
            {
                translateCategories.length > 0 ? translateCategories.map((element, index) => (
                    <div key={index} className={PWAPreviewStyle.category}>
                        <p className={PWAPreviewStyle.category__content}>{element}</p>
                    </div>
                )) : translateCategories.length = 0
            }
        </section>
    )
}

export default SectionCategories;