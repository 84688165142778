import {React, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import {useData} from "../../../Context/ContextApp";

const SectionDescription = () => {
    let {data} = useData();
    let titleDescription = data.landDescriptionTitle;
    let textDescription = data.landDescriptionText;
    useEffect(() => {
    }, [data]);

    return (
        <section className={`${PWAPreviewStyle.description} ${data.rtlDirection ? PWAPreviewStyle.rtl : ''}`}>
            <h1>{titleDescription.length > 0 ? titleDescription : 'Title description'}</h1>
            <p>{textDescription.length > 0 ? textDescription : 'Text description'}</p>
        </section>
    );
};

export default SectionDescription;