import {React, createContext, useContext, useState} from "../../imports/imports-packege";

const ContextApp = createContext();

export const DataProvider = ({children}) => {
    const [data, setData] = useState({
        builderMode: 'create',
        verifiedDeveloper: false,
        rtlDirection: false,
        landingType: '',
        languageBilder: '',
        keitaroId: '',
        oneSignalId: '',
        landName: '',
        langCode: '',
        iconUrl: '',
        iconFile: [],
        formDataScreen: [],
        screen_1: [],
        screen_2: [],
        screen_3: [],
        screen_4: [],
        screen_5: [],
        screen_6: [],
        parseContents: false,
        screenshotImages: [],
        author: '',
        categories: '',
        countComments: '',
        age: '',
        appSize: '',
        amountInstall: '',
        buttonText: '',
        landDescriptionTitle: '',
        landDescriptionText: '',
        multiCategories: [],
        recommendedApp: [],
        review: '',
        langReviewSelect: '',
        templateReview: [],
        query_user_url: '',
        query_url_open: '',
        query_link_lead_redirect: '',
        templateReviewCheck: true,
        TemplateReviewStatus: true,
        query_domain_user: '',
        amountComments: '',
        progressBars: [90, 50, 20, 17, 8],
        ratingStar: 4.5,
        responseReview: false,
        statusIconReview: true,
        statusIconTextReview: false,
        templateStatus: false,
        selectTemplateName: false,
        selectedTemplate: false,
        previewScreens: [null, null, null, null, null, null],
        mainElementColor: '#0957cf',
    });
    const updateData = (newData) => {
        setData((prevState) => ({ ...prevState, ...newData }));
    }

    return (
        <ContextApp.Provider value={{ data, updateData }}>
            {children}
        </ContextApp.Provider>
    );
}

export const useData = () => {
    return useContext(ContextApp)
}


